import { FC } from "react";
import PageTitle from "../PageTitle";
import "./../css/global.css";
import "./../css/text.css";
import { Flex } from "../util_components/Flex";
import Spacing from "./Spacing";

interface ProjectsPageProps {}

const ProjectsPage: FC<ProjectsPageProps> = () => {
  return (
    <div id="projects" className="page">
      <PageTitle title="projects" />
      <Spacing height={20} />
      <Flex height="100%" alignItems="center">
        {/* <h3>Some of the companies I've worked for:</h3> */}
        <Flex alignItems="center">
          <a href="https://play.google.com/store/apps/details?id=com.nutpan.jdoodle_app">
            <Flex flexDirection="row">
              <img src="./jdoodle_logo.png" alt="JDoodle App Logo" style={{ height: 65 }} />
              <Spacing width={20} />
              <h2 >JDoodle: Lead Mobile App Developer</h2>
            </Flex>
          </a>
          <p>
            As the sole mobile app developer at this startup I’ve been responsible for every part of the app development cycle — from product
            ownership through planning & coding, testing and release. I’ve brought in innovative ideas that not only have become key features in the
            app, but have also been adopted in other products throughout the entire company.
          </p>
        </Flex>

        <Flex alignItems="center">
          <a href="https://play.google.com/store/apps/details?id=com.simpro.mobile">
            <Flex flexDirection="row" alignItems="center">
              <img
                src="https://play-lh.googleusercontent.com/wkf_E0a8dFPcHHv6L4hL20N7ctWRwFBp0nX14mRifN4d5tiHOS9laeBTaVZW_U0kzw=w240-h480"
                alt="Simpro Mobile App Logo"
                style={{ height: 65, borderRadius: 12 }}
              />
              <Spacing width={20} />
              <h2>Simpro: Software Developer</h2>
            </Flex>
          </a>
          <p>
            At Simpro I worked as a Software Developer in an agile team developing a new Flutter web app / Flutter mobile app for designing and
            consuming electronic forms. We worked across the Flutter/Hasura/AWS stack, implementing database and API features as well as innovative
            user interfaces.
          </p>
        </Flex>

        <Flex alignItems="center">
          <a href="https://play.google.com/store/apps/details?id=de.gastivo.orderapp">
            <Flex flexDirection="row" alignItems="center">
              <img
                src="https://play-lh.googleusercontent.com/4TPw9sX_0JjYSj8CpDj3-wmgrFjTjre4HZqtExjbj1xEynj8efs00JaM52pryJmkaQ=w240-h480"
                alt="Gastivo App Logo"
                style={{ height: 65, borderRadius: 12 }}
              />
              <Spacing width={20} />
              <h2>Gastivo: Software Developer</h2>
            </Flex>
          </a>
          <p>
            My responsibilities as a Flutter developer at Gastivo included maintaining and developing new features for the company's widely-used
            restaurant order app. As part of a developer team I was also tasked with analysing user activities with Google Analytics, as well
            as new releases to the App Store and Play Store.
          </p>
        </Flex>
          {/* <a href="https://play.google.com/store/apps/details?id=app.nullify.mobile.app&gl=US&pli=1">
            <img
              src="https://play-lh.googleusercontent.com/GdlpV6h-_EIV1h3WrSaLZxsbuMggJDjz_yQDeZSHFlSa2kcgEDE_TI2A7ysmUOIyJw=w240-h480"
              alt="Nullify App Logo"
              style={{ height: 65 }}
            />
            <h2>Nullify</h2>
          </a> */}
        {/* </Flex> */}
        <h2>Projects of my own:</h2>
        <div className="responsive-flex-box">
          <a href="https://play.google.com/store/apps/details?id=au.com.cocreations.psiappX">
            <img src="./psi_logo.png" alt="Psi App Logo" style={{ height: 65 }} />
            <h2>psi: test of telepathic abilities app</h2>
          </a>
          <a href="https://statuesque-bonbon-5babdd.netlify.app">
            <img src="./wilde_party_logo.png" alt=" Wilde Party Logo" style={{ height: 65 }} />
            <h2>wilde party: browser card game</h2>
          </a>
          <a href="https://german-family-bakery.netlify.app/">
            <img src="./pretzel_black.png" alt="German Family Bakery website logo" style={{ height: 65 }} />
            <h2>german family bakery: a bakery website</h2>
          </a>
        </div>
      </Flex>
    </div>
  );
};

export default ProjectsPage;
